@import "app-fonts.css";
@import "components.css";
@import "colorful.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  @apply antialiased;
}

body {
  @apply font-outfit;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 300 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 300 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 300 84% 4.9%;

    --primary: 300 47.4% 15%;
    --primary-foreground: 279 40% 98%;

    --secondary: 279 40% 96.1%;
    --secondary-foreground: 300 47.4% 11.2%;

    --muted: 279 40% 96.1%;
    --muted-foreground: 285 16.3% 46.9%;

    --accent: 279 40% 96.1%;
    --accent-foreground: 300 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 279 40% 98%;

    --border: 279 31.8% 91.4%;
    --input: 279 31.8% 91.4%;
    --ring: 300 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

@layer components {
  .horizontal-visible-scrollbar::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 8px;
    }

    .horizontal-visible-scrollbar::-webkit-scrollbar-thumb {
      border-radius: 4px;
      height: 6px;
      @apply bg-lit-purple;
    }

    .vertical-visible-scrollbar::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 8px;
    }

    .vertical-visible-scrollbar::-webkit-scrollbar-thumb {
      border-radius: 4px;
      width: 6px;
      @apply bg-lit-purple;
    }
}
