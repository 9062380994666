@import "https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

:root {
  --background: 0 0% 100%;
  --foreground: 300 84% 4.9%;
  --card: 0 0% 100%;
  --card-foreground: 300 84% 4.9%;
  --popover: 0 0% 100%;
  --popover-foreground: 300 84% 4.9%;
  --primary: 300 47.4% 15%;
  --primary-foreground: 279 40% 98%;
  --secondary: 279 40% 96.1%;
  --secondary-foreground: 300 47.4% 11.2%;
  --muted: 279 40% 96.1%;
  --muted-foreground: 285 16.3% 46.9%;
  --accent: 279 40% 96.1%;
  --accent-foreground: 300 47.4% 11.2%;
  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 279 40% 98%;
  --border: 279 31.8% 91.4%;
  --input: 279 31.8% 91.4%;
  --ring: 300 84% 4.9%;
  --radius: .5rem;
}

* {
  border-color: hsl(var(--border));
}

body {
  background-color: hsl(var(--background));
  color: hsl(var(--foreground));
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

h1 {
  color: hsl(var(--primary));
  font-family: Outfit, sans-serif;
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 2.25rem;
}

h2 {
  color: hsl(var(--primary));
  font-family: Outfit, sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}

h3 {
  color: hsl(var(--primary));
  font-family: Outfit, sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
}

h4 {
  color: hsl(var(--primary));
  font-family: Outfit, sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.75rem;
}

h5 {
  color: hsl(var(--primary));
  font-family: Outfit, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
}

h6 {
  color: hsl(var(--primary));
  font-family: Outfit, sans-serif;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.25rem;
}

p {
  color: hsl(var(--primary) / .7);
  font-family: Outfit, sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
}

.lit-colorpicker .react-colorful {
  gap: 16px;
  width: 100%;
  height: 240px;
}

.lit-colorpicker .react-colorful__saturation {
  border-radius: 8px;
}

.lit-colorpicker .react-colorful__hue {
  border-radius: 6px;
  height: 12px;
}

.lit-colorpicker .react-colorful__hue-pointer {
  border-radius: 24px;
  width: 24px;
  height: 24px;
}

.horizontal-visible-scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 8px;
}

.horizontal-visible-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgb(68 26 93 / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
  border-radius: 4px;
  height: 6px;
}

.vertical-visible-scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
}

.vertical-visible-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgb(68 26 93 / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
  border-radius: 4px;
  width: 6px;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-auto {
  pointer-events: auto;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.inset-x-0 {
  left: 0;
  right: 0;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.-bottom-12 {
  bottom: -3rem;
}

.-left-12 {
  left: -3rem;
}

.-right-12 {
  right: -3rem;
}

.-top-12 {
  top: -3rem;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.left-1\/2 {
  left: 50%;
}

.left-2 {
  left: .5rem;
}

.left-\[50\%\] {
  left: 50%;
}

.right-0 {
  right: 0;
}

.right-2 {
  right: .5rem;
}

.right-4 {
  right: 1rem;
}

.top-0 {
  top: 0;
}

.top-1\/2 {
  top: 50%;
}

.top-2 {
  top: .5rem;
}

.top-4 {
  top: 1rem;
}

.top-\[50\%\] {
  top: 50%;
}

.z-0 {
  z-index: 0;
}

.z-50 {
  z-index: 50;
}

.z-\[-1\] {
  z-index: -1;
}

.z-\[100\] {
  z-index: 100;
}

.-mx-1 {
  margin-left: -.25rem;
  margin-right: -.25rem;
}

.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.-ml-4 {
  margin-left: -1rem;
}

.-mt-4 {
  margin-top: -1rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-auto {
  margin-left: auto;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-40 {
  margin-top: 10rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-auto {
  margin-top: auto;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.aspect-\[9\/16\] {
  aspect-ratio: 9 / 16;
}

.h-0 {
  height: 0;
}

.h-10 {
  height: 2.5rem;
}

.h-11 {
  height: 2.75rem;
}

.h-14 {
  height: 3.5rem;
}

.h-2 {
  height: .5rem;
}

.h-3 {
  height: .75rem;
}

.h-3\.5 {
  height: .875rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-8 {
  height: 2rem;
}

.h-9 {
  height: 2.25rem;
}

.h-\[320px\] {
  height: 320px;
}

.h-\[60px\] {
  height: 60px;
}

.h-\[var\(--radix-select-trigger-height\)\] {
  height: var(--radix-select-trigger-height);
}

.h-full {
  height: 100%;
}

.h-px {
  height: 1px;
}

.h-svh {
  height: 100svh;
}

.max-h-96 {
  max-height: 24rem;
}

.max-h-\[80svh\] {
  max-height: 80svh;
}

.max-h-screen {
  max-height: 100vh;
}

.max-h-svh {
  max-height: 100svh;
}

.min-h-40 {
  min-height: 10rem;
}

.min-h-svh {
  min-height: 100svh;
}

.w-0 {
  width: 0;
}

.w-10 {
  width: 2.5rem;
}

.w-16 {
  width: 4rem;
}

.w-2 {
  width: .5rem;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-3 {
  width: .75rem;
}

.w-3\.5 {
  width: .875rem;
}

.w-3\/4 {
  width: 75%;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-72 {
  width: 18rem;
}

.w-8 {
  width: 2rem;
}

.w-96 {
  width: 24rem;
}

.w-\[120px\] {
  width: 120px;
}

.w-\[180px\] {
  width: 180px;
}

.w-\[25\%\] {
  width: 25%;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.w-min {
  width: min-content;
}

.min-w-0 {
  min-width: 0;
}

.min-w-48 {
  min-width: 12rem;
}

.min-w-72 {
  min-width: 18rem;
}

.min-w-\[8rem\] {
  min-width: 8rem;
}

.min-w-\[var\(--radix-select-trigger-width\)\] {
  min-width: var(--radix-select-trigger-width);
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-sm {
  max-width: 24rem;
}

.flex-1 {
  flex: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.grow-0 {
  flex-grow: 0;
}

.basis-auto {
  flex-basis: auto;
}

.basis-full {
  flex-basis: 100%;
}

.-translate-x-1\/2 {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-x: -50%;
}

.-translate-y-1\/2 {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-y: -50%;
}

.translate-x-\[-50\%\] {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-x: -50%;
}

.translate-y-\[-50\%\] {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-y: -50%;
}

.rotate-90 {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-rotate: 90deg;
}

@keyframes progress-bar-slide {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(400%);
  }
}

.animate-progress-bar-slide {
  animation: 1.5s linear infinite progress-bar-slide;
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.grid-cols-\[minmax\(150px\,1fr\)\,2fr\] {
  grid-template-columns: minmax(150px, 1fr) 2fr;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-1 {
  gap: .25rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-20 {
  gap: 5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-8 {
  gap: 2rem;
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  --tw-space-x-reverse: 0;
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
  --tw-space-y-reverse: 0;
}

.space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
  margin-top: calc(.375rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.375rem * var(--tw-space-y-reverse));
  --tw-space-y-reverse: 0;
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
  --tw-space-y-reverse: 0;
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  --tw-space-y-reverse: 0;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-clip {
  overflow: clip;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.text-wrap {
  text-wrap: wrap;
}

.break-words {
  overflow-wrap: break-word;
}

.rounded {
  border-radius: .25rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-\[2px\] {
  border-radius: 2px;
}

.rounded-\[32px\] {
  border-radius: 32px;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: var(--radius);
}

.rounded-md {
  border-radius: calc(var(--radius)  - 2px);
}

.rounded-sm {
  border-radius: calc(var(--radius)  - 4px);
}

.rounded-xl {
  border-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.border-r {
  border-right-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-dashed {
  border-style: dashed;
}

.border-lit-purple {
  border-color: rgb(68 26 93 / var(--tw-border-opacity));
  --tw-border-opacity: 1;
}

.border-lit-purple\/50 {
  border-color: #441a5d80;
}

.border-primary {
  border-color: hsl(var(--primary));
}

.border-red-500 {
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
  --tw-border-opacity: 1;
}

.border-slate-200 {
  border-color: hsl(300 47.37% 88.3% / var(--tw-border-opacity));
  --tw-border-opacity: 1;
}

.border-transparent {
  border-color: #0000;
}

.bg-black\/80 {
  background-color: #000c;
}

.bg-lit-purple {
  background-color: rgb(68 26 93 / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
}

.bg-muted {
  background-color: hsl(var(--muted));
}

.bg-muted\/40 {
  background-color: hsl(var(--muted) / .4);
}

.bg-primary\/10 {
  background-color: hsl(var(--primary) / .1);
}

.bg-red-500 {
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
}

.bg-slate-100 {
  background-color: hsl(300 47.37% 93.7% / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
}

.bg-slate-300\/20 {
  background-color: #e7bbe733;
}

.bg-slate-400\/0 {
  background-color: #dc9edc00;
}

.bg-slate-900 {
  background-color: hsl(300 47.37% 14.9% / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
}

.bg-gradient-to-l {
  background-image: linear-gradient(to left, var(--tw-gradient-stops));
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.from-lit-purple {
  --tw-gradient-from: #441a5d var(--tw-gradient-from-position);
  --tw-gradient-to: #441a5d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.via-80\% {
  --tw-gradient-via-position: 80%;
}

.to-lit-pink {
  --tw-gradient-to: #f54a91 var(--tw-gradient-to-position);
}

.to-transparent {
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
}

.bg-cover {
  background-size: cover;
}

.bg-clip-text {
  background-clip: text;
}

.fill-current {
  fill: currentColor;
}

.object-cover {
  object-fit: cover;
}

.object-center {
  object-position: center;
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-64 {
  padding-top: 16rem;
  padding-bottom: 16rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-1\.5 {
  padding-bottom: .375rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-40 {
  padding-bottom: 10rem;
}

.pb-64 {
  padding-bottom: 16rem;
}

.pb-\[300px\] {
  padding-bottom: 300px;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-14 {
  padding-top: 3.5rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-8 {
  padding-top: 2rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-outfit {
  font-family: Outfit, sans-serif;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-8xl {
  font-size: 6rem;
  line-height: 1;
}

.text-\[12px\] {
  font-size: 12px;
}

.text-\[72px\] {
  font-size: 72px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.leading-\[1\.2\] {
  line-height: 1.2;
}

.leading-none {
  line-height: 1;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.tracking-widest {
  letter-spacing: .1em;
}

.text-gray-500 {
  color: rgb(107 114 128 / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.text-gray-800 {
  color: rgb(31 41 55 / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.text-green-700 {
  color: rgb(21 128 61 / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.text-green-800 {
  color: rgb(22 101 52 / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.text-lit-purple {
  color: rgb(68 26 93 / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.text-muted-foreground {
  color: hsl(var(--muted-foreground));
}

.text-primary {
  color: hsl(var(--primary));
}

.text-primary\/70 {
  color: hsl(var(--primary) / .7);
}

.text-red-500 {
  color: rgb(239 68 68 / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.text-red-800 {
  color: rgb(153 27 27 / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.text-slate-50 {
  color: hsl(300 47.37% 96.9% / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.text-slate-500 {
  color: hsl(300 47.37% 65% / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.text-slate-900 {
  color: hsl(300 47.37% 14.9% / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.text-slate-950 {
  color: hsl(300 47.37% 10% / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.text-slate-950\/50 {
  color: #260d2680;
}

.text-transparent {
  color: #0000;
}

.text-white {
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.text-white\/0 {
  color: #fff0;
}

.underline {
  text-decoration-line: underline;
}

.underline-offset-4 {
  text-underline-offset: 4px;
}

.opacity-0 {
  opacity: 0;
}

.opacity-50 {
  opacity: .5;
}

.opacity-60 {
  opacity: .6;
}

.opacity-70 {
  opacity: .7;
}

.opacity-90 {
  opacity: .9;
}

.shadow {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
}

.shadow-lg {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
}

.shadow-md {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
}

.shadow-sm {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.outline {
  outline-style: solid;
}

.outline-black\/20 {
  outline-color: #0003;
}

.ring-1 {
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
}

.ring-black\/50 {
  --tw-ring-color: #00000080;
}

.ring-offset-white {
  --tw-ring-offset-color: #fff;
}

.drop-shadow {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  --tw-drop-shadow: drop-shadow(0 1px 2px #0000001a) drop-shadow(0 1px 1px #0000000f);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[left\] {
  transition-property: left;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[width\] {
  transition-property: width;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@keyframes enter {
  from {
    opacity: var(--tw-enter-opacity, 1);
    transform: translate3d(var(--tw-enter-translate-x, 0), var(--tw-enter-translate-y, 0), 0) scale3d(var(--tw-enter-scale, 1), var(--tw-enter-scale, 1), var(--tw-enter-scale, 1)) rotate(var(--tw-enter-rotate, 0));
  }
}

@keyframes exit {
  to {
    opacity: var(--tw-exit-opacity, 1);
    transform: translate3d(var(--tw-exit-translate-x, 0), var(--tw-exit-translate-y, 0), 0) scale3d(var(--tw-exit-scale, 1), var(--tw-exit-scale, 1), var(--tw-exit-scale, 1)) rotate(var(--tw-exit-rotate, 0));
  }
}

.ease-in-out {
  animation-timing-function: cubic-bezier(.4, 0, .2, 1);
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: Outfit, sans-serif;
}

.file\:border-0::file-selector-button {
  border-width: 0;
}

.file\:bg-transparent::file-selector-button {
  background-color: #0000;
}

.file\:text-sm::file-selector-button {
  font-size: .875rem;
  line-height: 1.25rem;
}

.file\:font-medium::file-selector-button {
  font-weight: 500;
}

.placeholder\:text-slate-500::placeholder {
  color: hsl(300 47.37% 65% / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.hover\:bg-primary\/5:hover {
  background-color: hsl(var(--primary) / .05);
}

.hover\:bg-red-500\/90:hover {
  background-color: #ef4444e6;
}

.hover\:bg-slate-100:hover {
  background-color: hsl(300 47.37% 93.7% / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
}

.hover\:bg-slate-100\/80:hover {
  background-color: #f7e7f7cc;
}

.hover\:bg-slate-900\/90:hover {
  background-color: #381438e6;
}

.hover\:text-primary:hover {
  color: hsl(var(--primary));
}

.hover\:text-slate-900:hover {
  color: hsl(300 47.37% 14.9% / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.hover\:text-slate-950:hover {
  color: hsl(300 47.37% 10% / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.hover\:opacity-70:hover {
  opacity: .7;
}

.hover\:opacity-80:hover {
  opacity: .8;
}

.focus\:bg-slate-100:focus {
  background-color: hsl(300 47.37% 93.7% / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
}

.focus\:text-slate-900:focus {
  color: hsl(300 47.37% 14.9% / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.focus\:opacity-100:focus {
  opacity: 1;
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-2:focus {
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
}

.focus\:ring-slate-950:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(300 47.37% 10% / var(--tw-ring-opacity));
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.focus-visible\:outline-none:focus-visible {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus-visible\:ring-2:focus-visible {
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
}

.focus-visible\:ring-slate-950:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(300 47.37% 10% / var(--tw-ring-opacity));
}

.focus-visible\:ring-offset-2:focus-visible {
  --tw-ring-offset-width: 2px;
}

.disabled\:pointer-events-none:disabled {
  pointer-events: none;
}

.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.disabled\:opacity-50:disabled {
  opacity: .5;
}

.group:hover .group-hover\:bg-slate-400 {
  background-color: hsl(300 47.37% 74.1% / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
}

.group:hover .group-hover\:text-white {
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.group.destructive .group-\[\.destructive\]\:border-slate-100\/40 {
  border-color: #f7e7f766;
}

.group.destructive .group-\[\.destructive\]\:text-red-300 {
  color: rgb(252 165 165 / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.group.destructive .group-\[\.destructive\]\:hover\:border-red-500\/30:hover {
  border-color: #ef44444d;
}

.group.destructive .group-\[\.destructive\]\:hover\:bg-red-500:hover {
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
}

.group.destructive .group-\[\.destructive\]\:hover\:text-red-50:hover {
  color: rgb(254 242 242 / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.group.destructive .group-\[\.destructive\]\:hover\:text-slate-50:hover {
  color: hsl(300 47.37% 96.9% / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.group.destructive .group-\[\.destructive\]\:focus\:ring-red-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(248 113 113 / var(--tw-ring-opacity));
}

.group.destructive .group-\[\.destructive\]\:focus\:ring-red-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(239 68 68 / var(--tw-ring-opacity));
}

.group.destructive .group-\[\.destructive\]\:focus\:ring-offset-red-600:focus {
  --tw-ring-offset-color: #dc2626;
}

.peer:disabled ~ .peer-disabled\:cursor-not-allowed {
  cursor: not-allowed;
}

.peer:disabled ~ .peer-disabled\:opacity-70 {
  opacity: .7;
}

.data-\[disabled\]\:pointer-events-none[data-disabled] {
  pointer-events: none;
}

.data-\[side\=bottom\]\:translate-y-1[data-side="bottom"] {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-y: .25rem;
}

.data-\[side\=left\]\:-translate-x-1[data-side="left"] {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-x: -.25rem;
}

.data-\[side\=right\]\:translate-x-1[data-side="right"] {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-x: .25rem;
}

.data-\[side\=top\]\:-translate-y-1[data-side="top"] {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-y: -.25rem;
}

.data-\[swipe\=cancel\]\:translate-x-0[data-swipe="cancel"] {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-x: 0px;
}

.data-\[swipe\=end\]\:translate-x-\[var\(--radix-toast-swipe-end-x\)\][data-swipe="end"] {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-x: var(--radix-toast-swipe-end-x);
}

.data-\[swipe\=move\]\:translate-x-\[var\(--radix-toast-swipe-move-x\)\][data-swipe="move"] {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-x: var(--radix-toast-swipe-move-x);
}

.data-\[state\=active\]\:bg-white[data-state="active"] {
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
}

.data-\[state\=open\]\:bg-slate-100[data-state="open"] {
  background-color: hsl(300 47.37% 93.7% / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
}

.data-\[state\=active\]\:text-slate-950[data-state="active"] {
  color: hsl(300 47.37% 10% / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.data-\[state\=open\]\:text-slate-500[data-state="open"] {
  color: hsl(300 47.37% 65% / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.data-\[disabled\]\:opacity-50[data-disabled] {
  opacity: .5;
}

.data-\[state\=active\]\:shadow-sm[data-state="active"] {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
}

.data-\[swipe\=move\]\:transition-none[data-swipe="move"] {
  transition-property: none;
}

.data-\[state\=open\]\:animate-in[data-state="open"] {
  --tw-enter-opacity: initial;
  --tw-enter-scale: initial;
  --tw-enter-rotate: initial;
  --tw-enter-translate-x: initial;
  --tw-enter-translate-y: initial;
  animation-name: enter;
  animation-duration: .2s;
}

.data-\[state\=closed\]\:animate-out[data-state="closed"], .data-\[swipe\=end\]\:animate-out[data-swipe="end"] {
  --tw-exit-opacity: initial;
  --tw-exit-scale: initial;
  --tw-exit-rotate: initial;
  --tw-exit-translate-x: initial;
  --tw-exit-translate-y: initial;
  animation-name: exit;
  animation-duration: .2s;
}

.data-\[state\=closed\]\:fade-out-0[data-state="closed"] {
  --tw-exit-opacity: 0;
}

.data-\[state\=closed\]\:fade-out-80[data-state="closed"] {
  --tw-exit-opacity: .8;
}

.data-\[state\=open\]\:fade-in-0[data-state="open"] {
  --tw-enter-opacity: 0;
}

.data-\[state\=closed\]\:zoom-out-95[data-state="closed"] {
  --tw-exit-scale: .95;
}

.data-\[state\=open\]\:zoom-in-95[data-state="open"] {
  --tw-enter-scale: .95;
}

.data-\[side\=bottom\]\:slide-in-from-top-2[data-side="bottom"] {
  --tw-enter-translate-y: -.5rem;
}

.data-\[side\=left\]\:slide-in-from-right-2[data-side="left"] {
  --tw-enter-translate-x: .5rem;
}

.data-\[side\=right\]\:slide-in-from-left-2[data-side="right"] {
  --tw-enter-translate-x: -.5rem;
}

.data-\[side\=top\]\:slide-in-from-bottom-2[data-side="top"] {
  --tw-enter-translate-y: .5rem;
}

.data-\[state\=closed\]\:slide-out-to-bottom[data-state="closed"] {
  --tw-exit-translate-y: 100%;
}

.data-\[state\=closed\]\:slide-out-to-left[data-state="closed"] {
  --tw-exit-translate-x: -100%;
}

.data-\[state\=closed\]\:slide-out-to-left-1\/2[data-state="closed"] {
  --tw-exit-translate-x: -50%;
}

.data-\[state\=closed\]\:slide-out-to-right[data-state="closed"], .data-\[state\=closed\]\:slide-out-to-right-full[data-state="closed"] {
  --tw-exit-translate-x: 100%;
}

.data-\[state\=closed\]\:slide-out-to-top[data-state="closed"] {
  --tw-exit-translate-y: -100%;
}

.data-\[state\=closed\]\:slide-out-to-top-\[48\%\][data-state="closed"] {
  --tw-exit-translate-y: -48%;
}

.data-\[state\=open\]\:slide-in-from-bottom[data-state="open"] {
  --tw-enter-translate-y: 100%;
}

.data-\[state\=open\]\:slide-in-from-left[data-state="open"] {
  --tw-enter-translate-x: -100%;
}

.data-\[state\=open\]\:slide-in-from-left-1\/2[data-state="open"] {
  --tw-enter-translate-x: -50%;
}

.data-\[state\=open\]\:slide-in-from-right[data-state="open"] {
  --tw-enter-translate-x: 100%;
}

.data-\[state\=open\]\:slide-in-from-top[data-state="open"] {
  --tw-enter-translate-y: -100%;
}

.data-\[state\=open\]\:slide-in-from-top-\[48\%\][data-state="open"] {
  --tw-enter-translate-y: -48%;
}

.data-\[state\=open\]\:slide-in-from-top-full[data-state="open"] {
  --tw-enter-translate-y: -100%;
}

.dark\:border-red-900:is(.dark *) {
  border-color: rgb(127 29 29 / var(--tw-border-opacity));
  --tw-border-opacity: 1;
}

.dark\:border-slate-800:is(.dark *) {
  border-color: hsl(300 47.37% 24.7% / var(--tw-border-opacity));
  --tw-border-opacity: 1;
}

.dark\:bg-red-900:is(.dark *) {
  background-color: rgb(127 29 29 / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
}

.dark\:bg-slate-50:is(.dark *) {
  background-color: hsl(300 47.37% 96.9% / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
}

.dark\:bg-slate-800:is(.dark *) {
  background-color: hsl(300 47.37% 24.7% / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
}

.dark\:bg-slate-950:is(.dark *) {
  background-color: hsl(300 47.37% 10% / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
}

.dark\:text-slate-400:is(.dark *) {
  color: hsl(300 47.37% 74.1% / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.dark\:text-slate-50:is(.dark *) {
  color: hsl(300 47.37% 96.9% / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.dark\:text-slate-50\/50:is(.dark *) {
  color: #fbf3fb80;
}

.dark\:text-slate-900:is(.dark *) {
  color: hsl(300 47.37% 14.9% / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.dark\:ring-offset-slate-950:is(.dark *) {
  --tw-ring-offset-color: #260d26;
}

.dark\:placeholder\:text-slate-400:is(.dark *)::placeholder {
  color: hsl(300 47.37% 74.1% / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.dark\:hover\:bg-red-900\/90:hover:is(.dark *) {
  background-color: #7f1d1de6;
}

.dark\:hover\:bg-slate-50\/90:hover:is(.dark *) {
  background-color: #fbf3fbe6;
}

.dark\:hover\:bg-slate-800:hover:is(.dark *) {
  background-color: hsl(300 47.37% 24.7% / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
}

.dark\:hover\:bg-slate-800\/80:hover:is(.dark *) {
  background-color: #5d215dcc;
}

.dark\:hover\:text-slate-50:hover:is(.dark *) {
  color: hsl(300 47.37% 96.9% / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.dark\:focus\:bg-slate-800:focus:is(.dark *) {
  background-color: hsl(300 47.37% 24.7% / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
}

.dark\:focus\:text-slate-50:focus:is(.dark *) {
  color: hsl(300 47.37% 96.9% / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.dark\:focus\:ring-slate-300:focus:is(.dark *), .dark\:focus-visible\:ring-slate-300:focus-visible:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(300 47.37% 82% / var(--tw-ring-opacity));
}

.group.destructive .dark\:group-\[\.destructive\]\:border-slate-800\/40:is(.dark *) {
  border-color: #5d215d66;
}

.group.destructive .dark\:group-\[\.destructive\]\:hover\:border-red-900\/30:hover:is(.dark *) {
  border-color: #7f1d1d4d;
}

.group.destructive .dark\:group-\[\.destructive\]\:hover\:bg-red-900:hover:is(.dark *) {
  background-color: rgb(127 29 29 / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
}

.group.destructive .dark\:group-\[\.destructive\]\:hover\:text-slate-50:hover:is(.dark *) {
  color: hsl(300 47.37% 96.9% / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.group.destructive .dark\:group-\[\.destructive\]\:focus\:ring-red-900:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(127 29 29 / var(--tw-ring-opacity));
}

.dark\:data-\[state\=active\]\:bg-slate-950[data-state="active"]:is(.dark *) {
  background-color: hsl(300 47.37% 10% / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
}

.dark\:data-\[state\=open\]\:bg-slate-800[data-state="open"]:is(.dark *) {
  background-color: hsl(300 47.37% 24.7% / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
}

.dark\:data-\[state\=active\]\:text-slate-50[data-state="active"]:is(.dark *) {
  color: hsl(300 47.37% 96.9% / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.dark\:data-\[state\=open\]\:text-slate-400[data-state="open"]:is(.dark *) {
  color: hsl(300 47.37% 74.1% / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

@media (width >= 640px) {
  .sm\:bottom-0 {
    bottom: 0;
  }

  .sm\:right-0 {
    right: 0;
  }

  .sm\:top-auto {
    top: auto;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:max-w-sm {
    max-width: 24rem;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:flex-col {
    flex-direction: column;
  }

  .sm\:justify-end {
    justify-content: flex-end;
  }

  .sm\:gap-0 {
    gap: 0;
  }

  .sm\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    margin-right: calc(.5rem * var(--tw-space-x-reverse));
    margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
    --tw-space-x-reverse: 0;
  }

  .sm\:rounded-lg {
    border-radius: var(--radius);
  }

  .sm\:text-left {
    text-align: left;
  }

  .data-\[state\=open\]\:sm\:slide-in-from-bottom-full[data-state="open"] {
    --tw-enter-translate-y: 100%;
  }
}

@media (width >= 768px) {
  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:mb-16 {
    margin-bottom: 4rem;
  }

  .md\:mt-4 {
    margin-top: 1rem;
  }

  .md\:block {
    display: block;
  }

  .md\:hidden {
    display: none;
  }

  .md\:w-2\/3 {
    width: 66.6667%;
  }

  .md\:w-\[405px\] {
    width: 405px;
  }

  .md\:min-w-\[360px\] {
    min-width: 360px;
  }

  .md\:max-w-\[420px\] {
    max-width: 420px;
  }

  .md\:grid-cols-\[280px_1fr\] {
    grid-template-columns: 280px 1fr;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:gap-20 {
    gap: 5rem;
  }

  .md\:p-4 {
    padding: 1rem;
  }

  .md\:p-8 {
    padding: 2rem;
  }

  .md\:pt-0 {
    padding-top: 0;
  }

  .md\:text-left {
    text-align: left;
  }
}

@media (width >= 1024px) {
  .lg\:h-\[60px\] {
    height: 60px;
  }

  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.\[\&\>span\]\:line-clamp-1 > span {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}
/*# sourceMappingURL=index.12b2e165.css.map */
